<template>
    <div class="result">
        <h2>Advies:</h2>
        <h4>
            <span>{{ result }}</span>
            <span v-if="showCalendar"><br>Het is raadzaam om persoonlijk advies in te winnen. Plan direct een online call met ons in.</span>
        </h4>
        <footer>
            <a class="button secondary" href="https://calendly.com/numbar" target="_blank" v-if="showCalendar">Inplannen</a>
            <button class="button white" @click="doReset">Opnieuw</button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'QuizResult',
    props: {
        result: undefined,
        showCalendar: Boolean
    },
    methods: {
        doReset() {
            this.$emit('reset', event);
        }
    }
}
</script>