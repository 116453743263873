<template>
    <div class="quiz" v-if="isStarted">
        <h2 v-if="! isStarted">Welkom bij de quiz!</h2>
        <Question :question="this.currentQuestion" @answer="gotAnswer" v-if="! showResult && currentQuestionId" />
        <Result :result="result" :show-calendar="showCalendar" v-if="showResult" @reset="reset" />
    </div>
</template>

<script>
import Question from './QuizQuestion';
import Result from './QuizResult';

export default {
    name: 'Quiz',
    components: {
        Question,
        Result
    },
    computed: {
        currentQuestion() {
            const questions = this.questions.filter(q => q.id == this.currentQuestionId);
            if ( questions.length ) {
                return questions[0];
            } else {
                return {};
            }
        }
    },
    data() {
        return {
           isStarted: false,
           questions: [
               {
                   id: 1,
                   question: 'Wil je een stevig financieel en fiscaal fundament?',
                   answers: [
                       {
                           answer: 'Ja',
                           goto: 2,
                           showCalendar: true,
                       },
                       {
                           answer: 'Nee',
                           result: 'Ga opzoek naar een kantoor die graag alles op papier ontvangt',
                           showCalendar: false,
                       }
                   ],
               },
               {
                   id: 2,
                   question: 'Wil je zelf de administratie verwerken?',
                   answers: [
                       {
                           answer: 'Ja',
                           goto: 5,
                           result: 'Ons advies op basis van deze paar vragen zou dit pakket zijn: Moneybird',
                           showCalendar: true
                       },
                       {
                           answer: 'Nee',
                           goto: 3,
                           result: 'Ons advies op basis van deze paar vragen zou dit pakket zijn: Numbar Variant',
                           showCalendar: true
                       }
                   ],
               },
               {
                   id: 3,
                   question: 'Heb je behoefte aan tussentijds inzicht en contact?',
                   answers: [
                       {
                           answer: 'Ja',
                           goto: 4,
                           result: 'Ons advies op basis van deze paar vragen zou dit pakket zijn: Numbar Variant',
                           showCalendar: true
                       },
                       {
                           answer: 'Nee',
                           result: 'Ons advies op basis van deze paar vragen zou dit pakket zijn: Numbar Fundament',
                           showCalendar: true
                       }
                   ]
               },
               {
                   id: 4,
                   question: 'Wil jij grip op je onderneming en winstoptimalisatie?',
                   answers: [
                       {
                           answer: 'Ja',
                           goto: 5,
                           result: 'Ons advies op basis van deze paar vragen zou dit pakket zijn: Numbar Ambitie',
                           showCalendar: true
                       },
                       {
                           answer: 'Nee',
                           result: 'Ons advies op basis van deze paar vragen zou dit pakket zijn: Numbar Inzicht',
                           showCalendar: true
                       }
                   ]
               },
               {
                   id: 5,
                   question: 'Ben je eenmanszaak/ VOF of besloten vennootschap?',
                   answers: [
                       {
                           answer: 'Eenmanszaak / VOF',
                           result: '',
                           showCalendar: true
                       },
                       {
                           answer: 'Besloten vennootschap',
                           result: '',
                           showCalendar: true
                       }
                   ]
               },
           ],
           currentQuestionId: 1,
           result: '',
           showResult: false,
           showCalendar: false
        }
    },
    mounted() {
        this.isStarted = true;
    },
    methods: {
        reset() {
            this.currentQuestionId = 1;
            this.showResult = false;
            this.showCalendar = false;
            this.result = '';
        },
        gotAnswer(answer) {
            if ( answer.result !== undefined ) {
                this.currentQuestionId = 1;
                this.showCalendar = answer.showCalendar;

                if ( answer.result != '' ) {
                    console.log('result updated');
                    this.result = answer.result;
                }

                console.log(answer);
            }

            if ( answer.goto !== undefined ) {
                this.currentQuestionId = 0;
                const self = this;

                setTimeout( function() {
                    self.currentQuestionId = answer.goto;
                }, 100 );
            }
            else {
                this.showResult = true;
            }
        }
    }
}
</script>