<template>
    <div>
        <div class="question">
            <h2>Vraag:</h2>
            <h3>{{ question.question }}</h3>
        </div>
        <div class="answers">
            <div :key="answer.index" class="answer" v-for="answer in question.answers">
                <button class="button" @click="onAnswer(answer)" role="button">
                    {{ answer.answer }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuizQuestion',
    props: {
        question: Object,
    },
    methods: {
      onAnswer(answer) {
          event.preventDefault();
          this.$emit('answer', answer);
      }
    }
}
</script>