<template>
    <div id="quiz" class="text-center">
        <Quiz/>
    </div>
</template>

<script>
import 'reset-css'
import Quiz from './components/Quiz.vue'

export default {
  name: 'app',
  components: {
    Quiz
  }
}
</script>